var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn btn-info",
        on: {
          click: function($event) {
            return _vm.downloadCSV()
          }
        }
      },
      [
        _c("i", {
          staticClass: "fa fa-download",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" CSV")
      ]
    ),
    _c(
      "div",
      {
        staticClass: "row p-3",
        staticStyle: { "overflow-x": "auto", "max-height": "500px" }
      },
      [
        _c(
          "table",
          { staticClass: "table table-responsive-sm table-bordered" },
          _vm._l(_vm.tableData, function(row, x) {
            return _c(
              "tr",
              { key: x },
              _vm._l(row, function(col, y) {
                return _c("td", { key: y }, [
                  _vm.data[x][y] === "$ 0.00"
                    ? _c("div", [
                        _c("span", { staticStyle: { color: "lightgray" } }, [
                          _vm._v(" " + _vm._s(_vm.data[x][y]) + "  ")
                        ])
                      ])
                    : _vm._e(),
                  _vm.data[x][y] != "$ 0.00"
                    ? _c("div", [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(" " + _vm._s(_vm.data[x][y]) + "  ")
                        ])
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }