<template>
    <div>
        <button @click="downloadCSV()" class="btn btn-info"><i class="fa fa-download" aria-hidden="true"></i> CSV</button>
         <div class="row p-3" style="overflow-x: auto; max-height: 500px">
            <table class="table table-responsive-sm table-bordered">
                <tr v-bind:key="x" v-for="(row,x) in tableData">
                    <td v-bind:key="y" v-for="(col,y) in row">
                        <div v-if="data[x][y] === '$ 0.00'"> <span style="color:lightgray"> {{data[x][y]}}  </span>  </div>
                        <div v-if="data[x][y] != '$ 0.00'"> <span style="font-weight:bold"> {{data[x][y]}}  </span>  </div>                              
                    </td>
                </tr>
            </table>
        </div>
    </div>    
</template>

<script>

import {Multiselect} from 'vue-multiselect'


export default {
    name : 'Investigation',
    components : {
        Multiselect
    },
    props: ['rows','columns','data'],
    data (){
        return {

        }
    },

    computed : {
        tableData : function(){
            if(this.rows.length < 0 && this.columns.length < 0 ) 
                return;

            let tableData = this.data;

            //
            //  Limit length of columns and rows to 25
            //

            for(let y = 0 ; y < this.columns.length ; y++ ){
                if(this.columns[y].length > 35){
                    this.columns[y] = this.columns[y].substring(0,23) + '...';
                }
                
            }

            for(let y = 0 ; y < this.rows.length ; y++ ){
                if(this.rows[y].length > 35){
                    this.rows[y] = this.rows[y].substring(0,23) + '...';
                }
            }      



            console.log('computed');
            console.log('table data: ');
            console.log(tableData);

            //
            //  Add all the column headers to the front of each array 
            //  Columns headers found in column prop
            //
            //
            //  Convert:
            //
            //  [1,2,5]
            //  [3,4,6]
            //  [2,4,5]
            //
            //  To:
            //
            //  [COL1HEADER ,1,2,5]
            //  [COL2HEADER ,3,4,6]
            //  [COL3HEADER ,2,4,5]
            //
            //
            for(let y = 0 ; y < this.columns.length ; y++ ){
                tableData[y].unshift(this.columns[y]);
            }

            //
            //  Populate headers row
            //  Headers found in rows prop
            //
            //  
            //  Convert     
            //
            //  [COL1HEADER ,1,2,5]
            //  [COL2HEADER ,3,4,6]
            //  [COL3HEADER ,2,4,5]
            //
            //  To:
            //  [  -blank-  ,    HEADER1,    HEADER2,   HEADER3]
            //  [COL1HEADER ,   1,          2,          5]
            //  [COL2HEADER ,   3,          4,          6]
            //  [COL3HEADER ,   2,          4,          5]
            //
            //

            this.rows.unshift('');  //Insert a blank row in the front
            tableData.unshift(this.rows);


            //  Configure the x and y axis filter options
            //  At first look this seems wrong, however "rows" and "columns" are swapped here

            /* this.columnFilterOptions = this.rows.slice(1); // Remove front blank
            this.rowFilterOptions = this.columns;

            this.columnFilterSelection = this.rows.slice(1); // Remove front blank
            this.rowFilterSelection = this.columns; */

            return tableData;
        }
    },
    methods : {

        downloadCSV(){
            var csvRows = [];
            let dataArray = this.data;
            for (var i = 0; i < dataArray.length; ++i) {
                for (var j = 0; j < dataArray[i].length; ++j) {
                    dataArray[i][j] = '\"' + dataArray[i][j] + '\"';  // Handle elements that contain commas
                    dataArray[i][j] = dataArray[i][j].replace('$','');
                }
                csvRows.push(dataArray[i].join(','));
            }
  

            var csvString = csvRows.join('\r\n');
            var a         = document.createElement('a');
            a.href        = 'data:attachment/csv,' + csvString;
            a.target      = '_blank';
            a.download    = 'InvestigationsTable.csv';

            document.body.appendChild(a);
            a.click();  
        }
    }
}
</script>

<style scoped>

    td:nth-child(1){
        width:20%;
    }

    tr:nth-child(1),
    td:nth-child(1){
        background-color: #444;
        color: white; 
        font-size: 0.85em;
        font-weight: bold;   
    }

    tr:nth-child(1){
        max-width: 100px;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
    }

    td:nth-child(1){
        white-space: nowrap;  
    }
    
</style>
